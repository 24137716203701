import axios from "axios";
import qs from "qs";
import {
    getSystemLang
} from "../lang";


export default function($url,$param){

    let domain  = window.location.protocol+"//"+window.location.host;
    // axios.defaults.baseURL = '/test/api/index.php/';
    if(window.location.hostname=="localhost"){
        axios.defaults.baseURL = 'http://golf.me/';
    }else if(window.location.hostname=="pctest.wifigolf.com"){
        axios.defaults.baseURL = 'https://www.wifigolf.com/';
    }else{
        axios.defaults.baseURL = 'https://www.wifigolf.com/'; //正式
    }
    axios.defaults.withCredentials = true;

    let lang = getSystemLang();
    if(lang == 'cn')
    {
        lang = 'zh-cn';
    }
    else if(lang == 'en')
    {
        lang = 'en-us';
    }
    else if(lang == 'tw')
    {
        lang = 'zh-tw';
    }
    else
    {
        lang = 'zh-cn';
    }

    // let tparam = [];
    // if($param instanceof Object)
    // {
    //     for(let key in $param)
    //     {
    //         tparam.push(key+'='+ encodeURIComponent($param[key]));
    //     }
    // }

    // if(tparam.length > 0)
    // {

    //     $url = 'core/'+lang+'/'+$url+'?'+tparam.join("&"); 
    // }
    // else
    // {
    //     $url = 'core/'+lang+'/'+$url;
    // }

    $param.lang = lang;
    $url = $url;
    
    return axios.post($url,qs.stringify($param));
}