<template>
	<div v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
		element-loading-background="rgba(0, 0, 0, 0.3)" style="height: 100%;">
		<!-- 六人三球 -->
		<LRYQ v-if="matchmode=='LRYQ'" :matchhole="matchhole" :match="match" :match_set="match_set" :list="list" :playoff="playoff" :rd_id="rd_id" :teams="teams" :islcd="true"></LRYQ>
		<!-- 六人六球 -->
		<LRLQ v-if="matchmode=='LRLQ'" :matchhole="matchhole" :match="match" :match_set="match_set" :list="list" :playoff="playoff" :rd_id="rd_id" :teams="teams" :islcd="true"></LRLQ>
	</div>
</template>

<script>
	// @ is an alias to /src
	import navs from "../../components/nav.vue";
	import Btn from "../../components/Btn.vue";
	import Select from "../../components/select.vue";
	import Foot from "../../components/foot.vue";
	import LRYQ from "../../components/Tpga/LRYQ.vue";
	import LRLQ from "../../components/Tpga/LRLQ.vue";
	import axios from '../../axios/request';
	import {
		getSystemLang,
		getLangAll
	} from "../../lang";
	//这里就是更新后的路径，以前是import { Swiper, SwiperSlide } from 'swiper/vue'
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue/swiper-vue';

	import {
		Autoplay,
		Pagination
	} from 'swiper';
	import 'swiper/swiper-bundle.css';
	import 'swiper/swiper.min.css';
	//这里就是更新后的路径，以前是import 'swiper/css'
	//自动轮播的配置
	var app1 = {
		data() {
			return {
				langs: '',
                systemlang: 'cn',
                list:[],
                match:[],
                match_set:[],
                matchhole:[],
                playoff:[],
                bygroup:"",
                listmode:"",
                matchmode:"",
                loading:false,
                rd_id:"",
                leader:"",
                teams:[]
			}
		},
		props: {

		},
		name: 'Home',
		components: {
			navs,
			Btn,
			Select,
			Swiper,
			SwiperSlide,
			Foot,
			LRYQ,
			LRLQ
		},
		created() {
            this.systemlang = getSystemLang();
            this.langs = getLangAll(this.systemlang);
            var params = this.$route.query;
            var mt_id = this.getQueryVariable("MatchID")===false?31934:this.getQueryVariable("MatchID");
            var rd_id = params.RoundID==undefined?"":params.RoundID;
            var list = params.list==undefined?"":params.list;
            var bygroup = params.bygroup==undefined?"":params.bygroup;
            var lang = params.lang==undefined?"":params.lang;
            var g = params.g==undefined?"":params.g;
            var modal = params.modal==undefined?"":params.modal;
            var leader = params.leader==undefined?"":params.leader;
            if(bygroup == 1){
                leader = "";
            }
            this.modal = modal;
            this.listmode = list;
            this.bygroup=bygroup;
            this.leader = leader;
            this.loading = true;
            if(this.listmode == "pk"){
                axios('score/index.json',{mt_id:mt_id,rd_id:rd_id,list:list,bygroup:bygroup,lang:lang,gid:g,modal:modal,leader:leader})
                .then((res)=>{
                    var data = res.data.data;
                    this.list = data.list;
                    this.match = data.match;
                    this.match_set = data.match_set;
                    this.matchhole = data.matchhole;
                    this.teams = data.teams;
                    if(rd_id == ''){
                        this.rd_id = data.match_set.rd_id;
                    }else{
                        this.rd_id = rd_id;
                    }
                    if(this.match_set.pk[this.rd_id-1].type=="SRLQ_BDS"&&this.match_set.pk[this.rd_id-1].scoretype==1){
                        this.matchmode="LRYQ";
                    }else if(this.match_set.pk[this.rd_id-1].type=="SRSQ_BDS"&&this.match_set.pk[this.rd_id-1].scoretype==1){
                        this.matchmode="LRLQ";
                    }
                    // console.log(this.matchmode)// = "BGS9";
                    this.loading = false;
                });
				setInterval(() => {
                    axios('score/index.json',{mt_id:mt_id,rd_id:rd_id,list:list,bygroup:bygroup,lang:lang,gid:g,modal:modal,leader:leader})
                    .then((res)=>{
                        var data = res.data.data;
                        this.list = data.list;
                        this.match = data.match;
                        this.match_set = data.match_set;
                        this.matchhole = data.matchhole;
                        this.teams = data.teams;
                        if(rd_id == ''){
                            this.rd_id = data.match_set.rd_id;
                        }else{
                            this.rd_id = rd_id;
                        }
                        if(this.match_set.pk[this.rd_id-1].type=="SRLQ_BDS"&&this.match_set.pk[this.rd_id-1].scoretype==1){
                            this.matchmode="LRYQ";
                        }else if(this.match_set.pk[this.rd_id-1].type=="SRSQ_BDS"&&this.match_set.pk[this.rd_id-1].scoretype==1){
                            this.matchmode="LRLQ";
                        }
                        // console.log(this.matchmode)// = "BGS9";
                        this.loading = false;
                    });
                }, 300000);
            }else{
                axios('match/rankbgs.json',{mt_id:mt_id,rd_id:rd_id,list:list,bygroup:bygroup,lang:lang,gid:g,modal:modal,leader:leader})
                .then((res)=>{
                    var data = res.data.data;
                    this.list = data.list;
                    this.match = data.match;
                    this.match_set = data.match_set;
                    this.matchhole = data.matchhole;
                    this.playoff = data.playoff;
                    if(data.playoff.length==0){
                        this.match.playoffhole = 0;
                    }
                    if(rd_id == ''){
                        this.rd_id = data.match_set.rd_id;
                    }else{
                        this.rd_id = rd_id;
                    }
                    if(this.bygroup==1){
                        if(this.matchhole.hole.length==9){
                            this.matchmode = "group9";
                        }else{
                            this.matchmode = "group";
                        }
                    }else if(this.leader==1){
                        this.matchmode = "leader";
                    }else if(this.modal!='NetScore'&&this.matchhole.hole.length==27){
                        this.matchmode = "BGS27";
                    }else if(this.modal!='NetScore'&&this.matchhole.hole.length==9){
                        this.matchmode = "BGS9";
                    }else if(this.modal=='NetScore'&&(this.listmode=='NNP'||this.match_set.mt_scoring_mode=='NNP'||this.match_set.mt_scoring_mode=='NNP_ZBCD')){
                        if(this.match_set.mt_scoring_mode=='NNP_ZBCD'){
                            this.matchmode = "NNP_ZBCD";
                            this.listmode='NNP_ZBCD'
                        }else{
                            this.matchmode = "NNP";
                        }
                    }else if(this.modal=='NetScore'&&(this.listmode=='ZBCD'||this.match_set.mt_scoring_mode=='ZBCD')){
                        this.matchmode = "ZBCD";
                    }else if(this.modal!='NetScore'){
                        this.matchmode = "BGS";
                    }
                    // this.matchmode="leader";
                    // console.log(this.matchmode)// = "BGS9";
                    this.loading = false;
                });
                setInterval(() => {
                    this.getData(mt_id,rd_id,list,bygroup,lang,g,modal,leader);
                }, 300000);
            }
            
        },
        setup() {
            const onSwiper = (swiper) => {
                console.log(swiper);
            };
            const onSlideChange = (e) => {
                console.log('slide change123123123',e.activeIndex);
            };
            const autoplayOptions = {
                delay: 5000,
                disableOnInteraction: false,
                loop: false,
                pauseOnMouseEnter: true,
                reverseDirection: true
            };
            return {
                onSwiper,
                onSlideChange,
                autoplayOptions,
                modules: [Pagination,Autoplay],
            };
        },
        methods: {
			gofunction(e){
                console.log(this.match);
                this.$refs.select.getMatchList(e+'',this.match.club_id,this.match.tour_id,'go');
            },
            getQueryVariable(variable)
			{
				var query = window.location.search.substring(1);
				var vars = query.split("&");
                var returnval = "";
				for (var i=0;i<vars.length;i++) {
                    var pair = vars[i].split("=");
                    if(pair[0] == variable){returnval = pair[1];}
				}
                if(returnval!=""){
                    return returnval;
                }
				return(false);
			},
            getData(mt_id,rd_id,list,bygroup,lang,g,modal,leader){
                axios('match/rankbgs.json',{mt_id:mt_id,rd_id:rd_id,list:list,bygroup:bygroup,lang:lang,gid:g,modal:modal,leader:leader})
                .then((res)=>{
                    var data = res.data.data;
                    this.list = data.list;
                    this.playoff = data.playoff;
                    this.match_set = data.match_set;
                    if(data.playoff.length==0){
                        this.match.playoffhole = 0;
                    }
                });
            },
            setscorecolor(){
                for (let index = 0; index < document.getElementsByClassName("lf_score_9").length; index++) {
                    document.getElementsByClassName("lf_score_9")[index].style.backgroundColor = "#FFFFFF00";
                    document.getElementsByClassName("lf_score_9")[index].style.color = "#000000";
                }
                var colorstyle = this.match_set.colorstyle;
                for(var k in colorstyle){
                    for (let index = 0; index < document.getElementsByClassName("lf_score_"+colorstyle[k].id).length; index++) {
                        document.getElementsByClassName("lf_score_"+colorstyle[k].id)[index].style.backgroundColor = colorstyle[k].background;
                        document.getElementsByClassName("lf_score_"+colorstyle[k].id)[index].style.color = colorstyle[k].color;
                    }
                }
            }
        }
	}
	export default app1;
</script>
<style scoped="scoped">
	.lf_score_box {
		width: 1280px;
		margin: 0 auto;
		height: 100%;
		overflow: hidden;
	}

	.lf_score_list {
		position: relative;
	}

	.lf_score_list_box_swiper {
		width: 100%;
		height: 1000px;
	}

	.lf_1 {
		width: 110px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_2,
	.lf_3,
	.lf_4 {
		width: 390px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 22px;
	}

	.lf_tit_list_box {
		display: flex;
		flex-direction: column;
		margin-bottom: 30px;
		position: relative;
		z-index: 99;
		
	}

	.lf_tit_list_box_tit {
		display: flex;
		align-items: center;
		height: 60px;
		color: #fff;
	}

	.lf_tit_list_box_tit div {
		border-right: 1px solid #fff;
		border-bottom: 1px solid #fff;
	}

	.lf_tit_list_box_tit .lf_2,
	.lf_tit_list_box_tit .lf_3,
	.lf_tit_list_box_tit .lf_4 {
		font-size: 24px;
		font-weight: bold;
	}

	.lf_tit_list_box_tit .lf_1 {
		font-size: 22px;
		background-color: #3F3939;
	}

	.lf_tit_list_box_con .lf_1 {
		font-size: 22px;
	}

	.lf_tit_list_box_tit .lf_2 {
		background-color: #E5007F;
	}

	.lf_tit_list_box_tit .lf_3 {
		background-color: #A7CD46;
	}

	.lf_tit_list_box_tit .lf_4 {
		background-color: #00AEEB;
	}

	.lf_1 {
		font-weight: bold;
	}

	.lf_tit_list_box_con {
		display: flex;
		align-items: center;
		height: 80px;
		color: #3F3939;
		background-color: #fafafa;
	}

	.lf_tit_list_box_con div {
		border-right: 1px solid #fff;
		border-bottom: 1px solid #fff;
	}

	.lf_tit_list_box_con .lf_2 {
		
		color: #E5007F;
		font-size: 30px;
	}

	.lf_tit_list_box_con .lf_3 {
		color: #A7CD46;
		font-size: 30px;
	}

	.lf_tit_list_box_con .lf_4 {
		color: #00AEEB;
		font-size: 30px;
	}

	.lf_tit_list_box_con .lf_2 span,
	.lf_tit_list_box_con .lf_3 span,
	.lf_tit_list_box_con .lf_4 span {
		font-weight: bold;
	}


	.lf_con_list_box {
		display: flex;
		flex-direction: column;
	}

	.lf_con_list_box_tit {
		display: flex;
		align-items: center;
		height: 60px;
		color: #fff;
	}

	.lf_con_list_box_tit div {}

	.lf_con_list_box_tit .lf_2,
	.lf_con_list_box_tit .lf_3,
	.lf_con_list_box_tit .lf_4 {
		font-size: 22px;
		display: flex;
		align-items: center;
	}

	.lf_con_list_box_tit .lf_1 {
		font-size: 22px;
		background-color: #3F3939;
	}

	.lf_con_list_box_tit .lf_2 {
		
		background-color: #E5007F;
	}

	.lf_con_list_box_tit .lf_3 {
		background-color: #A7CD46;
	}

	.lf_con_list_box_tit .lf_4 {
		background-color: #00AEEB;
	}

	.lf_con_1 {
		width: 270px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_con_2 {
		width: 120px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 24px;
	}

	/* 展开 s */
	.lf_zhankai_box{
		overflow: hidden;
	}
	.lf_zk_tit_box {
		display: flex;
		align-items: center;
		height: 60px;
		color: #3F3939;
		border-bottom: 1px solid #dbdbdb;
		background-color: #F0F0F0;
		font-size: 20px;
	}

	.lf_zk_tit_box div {
		height: 100%;
	}

	.lf_zk_1 {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 110px;
	}

	.lf_zk_2 {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 290px;
		flex-grow: 1;
	}

	.lf_zk_3 {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 85px;
	}

	.lf_zk_4 {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 60px;
		flex-direction: column;
	}

	.lf_zk_4 div {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50%;
		width: 100%;
	}

	.lf_zk_5 {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 80px;
		flex-direction: column;
	}

	.lf_zk_5 div {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50%;
		width: 100%;
	}

	.lf_zk_4_1 {
		background-color: #DBDBDB;
	}

	.lf_zk_6 {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 75px;
	}

	.lf_zk_7 {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 80px;
	}

	.lf_zk_con_box {
		display: flex;
		align-items: center;
		height: 160px;
		color: #000;
		border-bottom: 1px solid #dbdbdb;
	}

	.lf_zk_con_box .lf_zk_1 {
		font-size: 24px;
		height: 100%;
	}

	.lf_zk_con_box .lf_zk_2 {
		font-size: 22px;
		display: flex;
		flex-direction: column;
		height: 100%;
		align-items: flex-start;
		padding-left: 10px;
	}

	.lf_zk_con_box .lf_zk_2 div {
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
		flex-direction: column;
		width: 100%;
		height: 50%;
	}

	.lf_zk_con_box .lf_zk_2 div span {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

	.lf_zk_con_box .lf_zk_2 div span:nth-last-of-type(1) {
		font-size: 16px;
		color: #55565B;
		margin-bottom: 6px;
	}

	.lf_zk_con_box .lf_zk_2 div .lf_sex_box_b {
		display: flex;
		align-items: center;
		flex-direction: row;
		width: auto;
	}

	.lf_zk_con_box .lf_zk_2 .lf_sex_box {
		width: 24px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-weight: bold;
		background-color: rgba(238, 238, 238, 1);
		float: right;
		margin-right: 6px;
		border-radius: 50%;
	}

	.lf_zk_con_box .lf_zk_2 .lf_sex_box img {
		width: 16px;
		height: 16px;
	}

	.lf_zk_con_box .lf_zk_3 {
		font-size: 24px;
		color: #000;
	}

	.lf_zk_con_box .lf_zk_4 {
		font-size: 24px;
		color: #000;
		height: 100%;
	}

	.lf_zk_con_box .lf_zk_5 {
		font-size: 24px;
		color: #000;
		height: 100%;
		font-weight: bold;
	}

	.lf_zk_con_box .lf_zk_6 {
		font-size: 24px;
		color: #000;
		height: 100%;
		background-color: #F0F0F0;
	}

	.lf_zk_con_box .lf_zk_7 {
		font-size: 24px;
		color: #000;
		height: 100%;
		font-weight: bold;
	}

	/* 展开 end */
	.lf_T1_bg {
		background-color: #E5007F;
	}

	.lf_T2_bg {
		background-color: #A7CD46;
	}
	.lf_T3_bg {
		background-color: #00AEEB;
	}
	.lf_F_Lv {
		font-size: 24px;
		color: #027512 !important;
		font-weight: bold;
	}

	@media screen and (max-width: 768px) {}
</style>