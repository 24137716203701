
<template>
    <div>
        <navs></navs>
        <div class="lf_login_page">
			<div class="lf_login_box">
				<div class="lf_login_box_top">
					<div class="lf_login_box_top_l">
						<img src="../assets/images/u6.png" alt="">
						巡回赛系统
					</div>
					<div class="lf_login_box_top_r">
						致力于打造职业赛标准的赛事系统
					</div>
				</div>
				<div class="lf_login_box_mid">
					<div class="lf_login_box_mid_t">
						<div class="lf_login_box_mid_t_l">
							<div class="lf_login_box_mid_t_l_1">
								组织者登录
								<span>注册</span>
							</div>
							<div class="lf_login_box_mid_t_l_2">
								欢迎回来！请登录您的账号
							</div>
							<!-- 绑定过直接登录 -->
							<div v-if="c.flag != 2" class="lf_login_box_mid_t_l_3">
								<div @click="LoginType(1)" class="lf_login_box_mid_t_l_3_tab" :class="loginType==1?'lf_login_box_mid_t_l_3_tab_s':''">扫码登录</div>
								<div @click="LoginType(2)" class="lf_login_box_mid_t_l_3_tab" :class="loginType==2?'lf_login_box_mid_t_l_3_tab_s':''">账号登录</div>
							</div>
							<!-- 未绑定 -->
							<div v-if="c.flag == 2" class="lf_login_box_mid_t_l_3">
								<div style="border: none;" class="lf_login_box_mid_t_l_3_tab lf_login_box_mid_t_l_3_tab_s">
									请使用微信扫码绑定平台账号
									<div id="wxloginbox" class="lf_login_box_mid_t_l_4_1_code_img" >
									<wxlogin
											appid="wxff2e44fd58aad8f9"
											:scope="'snsapi_login'"
											:theme="'black'"
											:redirect_uri="encodeURIComponent('https://www.wifigolf.com/index.php/Home/Index/wxLoginIn?lang='+systemlang)"
											rel="external nofollow"></wxlogin>
									</div>
								</div>
							</div>
							<div class="lf_login_box_mid_t_l_4">
								<div  v-if="c.flag == 0 && loginType==1" class="lf_login_box_mid_t_l_4_1">
									<!-- <div  class="lf_login_box_mid_t_l_4_1_code">
										<img src="../assets/images/u235.svg" alt="">
										
									</div> -->
									<div id="wxloginbox" class="lf_login_box_mid_t_l_4_1_code_img" >
										<wxlogin
											appid="wxff2e44fd58aad8f9"
											:scope="'snsapi_login'"
											:theme="'black'"
											:redirect_uri="encodeURIComponent('https://www.wifigolf.com/index.php/Home/Index/wxLoginIn?lang='+systemlang)"
											rel="external nofollow"></wxlogin>
									</div>
									使用微信扫码
								</div>
								<!--微信扫码登录开始-->
								<!-- <div v-if="c.flag == 0 && loginType==1" class="lf_login_box_mid_t_l_4_1">
									<div  class="lf_login_box_mid_t_l_4_1_code">
										<img src="../assets/images/u235.svg" alt="">
										<div>微信扫码成功</div>
										<div>请输入账号信息，<span> 进行绑定</span></div>
									</div>
									<img class="lf_login_box_mid_t_l_4_1_code_img" src="../assets/images/u95.png" alt="">
									使用微信扫码
								</div> -->
								<!--微信扫码登录结束-->
								<!--微信扫码登录开始-->
								<div v-if="c.flag == 3 && loginType==1" class="lf_login_box_mid_t_l_4_1">
									<div  class="lf_login_box_mid_t_l_4_1_code">
										<img src="../assets/images/u235.svg" alt="">
										<div>微信扫码成功</div>
										<div>请输入账号信息，<span @click="goBind"> 进行绑定</span></div>
									</div>
									<img class="lf_login_box_mid_t_l_4_1_code_img" src="../assets/images/u95.png" alt="">
									使用微信扫码
								</div>
								<!--微信扫码登录结束-->
								<div v-if="c.flag==5&&loginType==2" class="lf_login_box_mid_t_l_4_2">
									<div style="height: 230px;overflow-y: scroll;">
									<div class="lf_login_box_mid_t_l_4_2_list lf_login_box_mid_t_l_4_2_list_no">
										<img src="../assets/images/g.png" alt="">
										test@situne.cn <span>（巡回赛名称）</span>
									</div>
									<div class="lf_login_box_mid_t_l_4_2_list lf_login_box_mid_t_l_4_2_list_no">
										<img src="../assets/images/q.png" alt="">
										test@situne.cn<span>（巡回赛名称）</span>
									</div>
									</div>
									<div class="lf_t_f_box">
										<div class="lf_login_box_mid_t_l_4_2_list lf_f_box">
											登 录
										</div>
									</div>
								</div>
								<div v-if="(c.flag==0||c.flag==3)&&loginType==2" class="lf_login_box_mid_t_l_4_2">
									<div class="lf_login_box_mid_t_l_4_2_list">
										<img src="../assets/images/u18.png" alt="">
										<input type="text" v-model="formData.account" placeholder="请输入登录账号">
									</div>
									<div class="lf_login_box_mid_t_l_4_2_list">
										<img src="../assets/images/u16.png" alt="">
										<input :type="passwordType" v-model="formData.pass"  placeholder="请输入密码">
										<img :src="status"  @click="changeStatus()" alt="" />
									</div>
									<div class="lf_t_f_box">
										<div class="lf_login_box_mid_t_l_4_2_list" style="width: 330px;">
											<img src="../assets/images/u20.png" alt="">
											<input style="width: 330px;"  v-model="formData.code" type="text" placeholder="请输入验证码">
										</div>
										<div class="lf_four_code_img">
											<img :src="imageUrl" @click="getImageCode" alt="" />
										</div>
									</div>
									<div class="lf_t_f_box">
										<div class="lf_login_box_mid_t_l_4_2_list lf_f_box" @click="login()" style="width: 330px;">
											登 录
										</div>
										<div class="lf_four_code_img lf_f_box_1" @click="clickwjmm=true">
											忘记密码
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="lf_login_box_mid_t_r">
							<img src="../assets/images/u72.svg" alt="">
							<div>个人登录</div>
							<img class="lf_ad_p_i" src="../assets/images/u33.png" alt="" />
						</div>
					</div>
					<!-- 忘记密码 -->
					<div v-if="clickwjmm" class="lf_alert_box"  @click="clickwjmm=false">
						<div class="lf_alert_box_con">
							<div class="lf_alert_box_con_1">
								<div>
									请发送 <span>账号 </span>及 <span>公司名称 </span>
								</div>
								<div>
									至<span> service@wifigolf.com</span>
								</div>
							</div>
							<div class="lf_alert_box_con_2">
								确定
							</div>
						</div>
					</div>
				</div>
				
			</div>
			<div class="lf_login_box_bottom">
				<swiper ref="dddd" :modules="modules" :space-between="40" :slides-per-view="3" :autoplay="autoplayOptions" :pagination="{ clickable: true }" @swiper="onSwiper"
					@slideChange="onSlideChange" class="lf_banner_div_left_img">
					<swiper-slide  v-for="b in [1,2,3,3,4,4,4,4]">
						
								<img src="../assets/images/1_u5.png" alt="">
							
						
					</swiper-slide>
				</swiper>
				
			</div>
		</div>
		<Foot HonePage="login"></Foot>
    </div>
	
</template>

<script>

    // @ is an alias to /src
    import navs from "../components/nav.vue";
    import Btn from "../components/Btn.vue";
	import Foot from "../components/foot.vue";
    import axios from '../axios/post';
	import wxlogin from 'vue-wxlogin';
	
    import {
        getSystemLang,
        getLangAll
    } from "../lang";
    //这里就是更新后的路径，以前是import { Swiper, SwiperSlide } from 'swiper/vue'
    import {
        Swiper,
        SwiperSlide
    } from 'swiper/vue/swiper-vue';

    import {
        Autoplay,Pagination
    } from 'swiper';
    import 'swiper/swiper-bundle.css';
    import 'swiper/swiper.min.css';
    //这里就是更新后的路径，以前是import 'swiper/css'
    //自动轮播的配置
    var app1 = {
        data() {
			
			var baseurl =  window.location.protocol+"//"+window.location.host+'/';
			var timenow = new Date().getTime();
            return {
                langs: '',
                systemlang: 'cn',
				formData:{
					account:'',
					pass:'',
					code:''
				},
                rd_id:"",
                leader:"",
                teams:[],
				loginType:0,
				status:require('../assets/images/11_u31.svg'),
				passwordType:'password',
				clickwjmm:false,
				baseurl:baseurl,
				imageUrl:baseurl+'index.php/Home/Index/verify_c/'+timenow,
				c:{} //$flag  1：登录成功 2：账号登录成功未绑定 3：微信登录成功未绑定账号 0：未登录 5:微信登录成功 需要选择账号
            }
        },
        props: {

        },
        name: 'Home',
        components: {
            navs,
            Btn,
            Swiper,
            SwiperSlide,
			Foot,
			wxlogin
        },
        created() {
            this.systemlang = getSystemLang();
            this.langs = getLangAll(this.systemlang);
            var params = this.$route.query;
            var lang = params.lang==undefined?"":params.lang;
			this.initLogin();
			
            
        },
        setup() {
            const onSwiper = (swiper) => {
                console.log(swiper);
            };
            const onSlideChange = (e) => {
                console.log('slide change123123123',e.activeIndex);
            };
            const autoplayOptions = {
                delay: 5000,
                disableOnInteraction: false,
                loop: true,
                pauseOnMouseEnter: true,
                reverseDirection: true
            };
            return {
                onSwiper,
                onSlideChange,
                autoplayOptions,
                modules: [Pagination,Autoplay],
            };
        },
        methods: {
			goBind(){
				this.loginType = 2;
			},
			//初始化登录页面
			initLogin(callback){
				this.checkUserLogin((data)=>{
				this.c = data;
				if(this.c.flag == '0' || this.c.flag == '2')
				{
					this.loginType=1;
				
				}
				else if(this.c.flag == '1')
				{
					window.location.href = this.c.data;
				}
				else if(this.c.flag == '3' || this.c.flag == '5')
				{
					this.loginType=2;
				}
				callback&&callback(data);
			});
			},

			//登录校验
			login(){
				if(this.formData.account == '')
				{
					alert('请输入账号');
					return false;
				}
				if(this.formData.pass == '')
				{
					alert('请输入密码');
					return false;
				}
				if(this.formData.code == '')
				{
					alert('请输入验证码');
					return false;
				}
				axios('index.php/Home/Index/checkLoginNew',this.formData)
                .then((res)=>{
					let data = res.data;
					if(data.flag == '0')
					{
						alert(data.info);
						return false;
					}
					if(data.flag == '1')
					{
						this.initLogin();
					}
					
                });
			},
			//获取图片验证码
			getImageCode()
			{
				var timenow = new Date().getTime();
				this.imageUrl=this.baseurl+'index.php/Home/Index/verify_c/'+timenow;
			},
			checkUserLogin(callback)
			{
				axios('index.php/Home/Index/checkUserLogin',{})
                .then((res)=>{
					callback&&callback(res.data);
                });
			},
			LoginType(t){
				this.loginType=t;
			},
			changeStatus(){
				this.passwordType = this.passwordType==='password'?'text':'password';
				this.status = this.status == require('../assets/images/11_u31.svg')?require('../assets/images/22_u30.svg'):require('../assets/images/11_u31.svg');
			}
			
        }
    }
    export default app1;
</script>
<style scoped="scoped">
	.lf_login_page{
		width: 100%;
		background-color: rgba(240, 243, 246, 1);
	}
	.lf_login_box{
		width: 1440px;
		margin: 0 auto;
		padding: 70px 80px;
	}
	.lf_login_box_top{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.lf_login_box_top_l{
		display: flex;
		align-items: flex-end;
		font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
		font-weight: 700;
		font-style: normal;
		font-size: 34px;
		color: #231815;
		line-height: 36px;
	}
	.lf_login_box_top_l img{
		width: 200px;
		height: 53px;
	}
	.lf_login_box_top_r{
		font-family: '楷体 Bold', '楷体 Regular', '楷体';
		    font-weight: 700;
		    font-style: normal;
		    font-size: 24px;
		    color: #259F39;
		    text-align: right;
	}
	.lf_login_box_mid{
		width: 1280px;
		height: 600px;
		background: inherit;
		background-color: rgba(255, 255, 255, 1);
		border: none;
		border-radius: 0px;
		box-shadow: 0px 0px 10px rgba(157, 157, 157, 0.349019607843137);
		margin-top: 30px;
		position: relative;
	}
	.lf_login_box_mid_t{
		display: flex;
		
	}
	.lf_login_box_mid_t_l{
		flex-grow: 1;
	}
	
	.lf_login_box_mid_t_l_1{
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
		font-weight: 700;
		font-style: normal;
		font-size: 26px;
		color: #0066CC;
		height: 66px;
		padding-left: 170px;
	}
	.lf_login_box_mid_t_l_1 span{
		font-size: 18px;
		border-left: 1px solid #bbbbbb;
		width: 120px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 50px;
		font-weight: 100;
	}
	.lf_login_box_mid_t_l_2{
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 16px;
		color: #999999;
	}
	.lf_login_box_mid_t_l_3{
		display: flex;
		justify-content: center;
		margin-top: 30px;
	}
	.lf_login_box_mid_t_l_3_tab{
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		color: #333;
		text-align: center;
		width: 170px;
		height: 54px;
		border-bottom: 2px solid #fff;
		font-size: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 40px;
	}
	.lf_login_box_mid_t_l_3_tab_s{
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		color: #0066CC;
		text-align: center;
		border-bottom: 2px solid #0066CC;
	}
	.lf_login_box_mid_t_l_4_1{
		display: flex;
		width: 480px;
		margin: 0 auto;
		position: relative;
		flex-direction: column;
		align-items: center;
		color: #999;
		font-size: 16px;
	}
	.lf_login_box_mid_t_l_4_1_code{
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: center;
		background-color: rgba(255, 255, 255, 0.949019607843137);
		position: absolute;
		width: 254px;
		height: 254px;
		top: 50px;
	}
	.lf_login_box_mid_t_l_4_1_code img{
		width: 40px;
		height: 40px;
		margin-top: 80px;
	}
	.lf_login_box_mid_t_l_4_1_code div{
		font-size: 16px;
		color: #333;
		margin-top: 20px;
	}
	.lf_login_box_mid_t_l_4_1_code div span{
		font-size: 16px;
		color: #0066CC;
	}
	.lf_login_box_mid_t_l_4_1_code_img{
		width: 254px;
		height: 254px;
		margin-top: 50px;
		margin-bottom: 15px;
	}
	
	
	.lf_login_box_mid_t_l_4_2{
		display: flex;
		margin: 0 auto;
		flex-direction: column;
	}
	.lf_login_box_mid_t_l_4_2_list{
		width: 480px;
		height: 54px;
		padding: 3px 2px 3px 2px;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 20px;
		text-decoration: none;
		letter-spacing: normal;
		color: #000000;
		vertical-align: none;
		text-align: left;
		background-color: rgba(244, 244, 244, 1);
		display: flex;
		align-items: center;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(227, 227, 227, 1);
		margin: 20px auto 0;
		border-radius: 5px;
		
	}
	.lf_login_box_mid_t_l_4_2_list img{
		width: 26px;
		height: 26px;
		margin: 0 10px;
	}
	.lf_login_box_mid_t_l_4_2_list input{
		width: 480px;
		height: 54px;
		background-color: unset;
		font-size: 20px;
		color: #000;
	}
	.lf_login_box_mid_t_l_4_2_list_no{
		background-color: #fff;
		border: none;
		color: #000;
		font-size: 20px;
	}
	.lf_login_box_mid_t_l_4_2_list_no img{
		width: 20px;
		height: 20px;
	}
	.lf_login_box_mid_t_l_4_2_list_no span{
		font-size: 16px;
	}
	.lf_t_f_box{
		display: flex;
		align-items: center;
		width: 480px;
		margin: 0 auto;
	}
	.lf_four_code_img{
		width: 139px;
		height: 54px;
		background: inherit;
		background-color: rgba(255, 255, 255, 1);
		box-sizing: border-box;
		border-width: 2px;
		border-style: solid;
		border-color: rgba(37, 159, 57, 1);
		border-radius: 5px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 20px;
		color: #259F39;
		margin:20px 0 0 10px;
	}
	.lf_four_code_img img{
		width: 100%;
		height: 100%;
	}
	.lf_f_box{
		background-color: rgba(0, 102, 204, 1);
		border: none;
		border-radius: 5px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 24px;
		color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_f_box_1{
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 20px;
		color: #666666;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		    border-width: 2px;
		    border-style: solid;
		    border-color: rgba(102, 102, 102, 1);
	}
	
	
	
	
	.lf_login_box_mid_t_r{
		width: 320px;
		position: relative;
		height: 65px;
	}
	.lf_login_box_mid_t_r img{
		width: 320px;
		position: absolute;
		right: 0;
		z-index: 1;
	}
	.lf_login_box_mid_t_r div{
		width: 320px;
		height: 65px;
		position: relative;
		z-index: 2;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 20px;
		color: #FFFFFF;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		text-indent: 60px;
	}
	.lf_login_box_mid_t_r .lf_ad_p_i{
		width: 427px;
		height: 367px;
		position: absolute;
		right: 50px;
		top: 126px;
	}
	
	
	
	.lf_login_box_bottom{
		width: 1280px;
		margin: 0 auto;
		padding-bottom: 40px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.lf_login_box_bottom swiper-slide{
		width: 300px;
		height: 100px;
		margin: 0 40px;
	}
	.lf_login_box_bottom img{
		
		width: 300px;
		height: 100px;
	}
	.lf_login_box_bottom img:hover{
		transform: scale(1.05);
	}
	
	.lf_alert_box{
		position: absolute;
		top: 0;
		left: 0;
		z-index: 3;
		background-color: rgba(0, 0, 0, 0.298039215686275);
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}
	.lf_alert_box_con{
		width: 550px;
		height: 170px;
		background: inherit;
		background-color: rgba(255, 255, 255, 1);
		box-sizing: border-box;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(213, 213, 213, 1);
		border-radius: 5px;
		display: flex;
		flex-direction: column;
	}
	.lf_alert_box_con_1{
		font-size: 20px;
		color: #333;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		flex-grow: 1;
	}
	.lf_alert_box_con_1 div span{
		color: #4B5D9D;
	}
	.lf_alert_box_con_2{
		border-top: 1px solid rgba(213, 213, 213, 1);
		font-size: 24px;
		color: #333;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 55px;
		width: 530px;
		margin: 0 auto;
	}
    @media screen and (max-width: 768px) {
        
    }
</style>
